"use strict";
import {
  Add_default,
  Checkbox,
  CheckboxBase,
  CheckboxInvalid,
  CloseLg_default,
  DefaultPetProfile_default,
  IconAdd,
  IconAddCircleOutline_default,
  IconAddOutlineBlue_default,
  IconBigCalendar_default,
  IconCalendar_default,
  IconCat,
  IconCatDog_default,
  IconCloseOutlined_default,
  IconClose_default,
  IconDefaultProfilePic_default,
  IconDismissButton_default,
  IconDog,
  IconDownArrow_default,
  IconEditPhoto_default,
  IconEditSquareOutine_default,
  IconExpandDown_default,
  IconFoodFinder_default,
  IconHome,
  IconInfo_default,
  IconLeading_default,
  IconNext_default,
  IconPrev_default,
  IconSetting,
  IconWarning_default,
  PersonOutline_default,
  ToastCross_default,
  ToastSuccess_default
} from "./chunk-ZHIUNLYG.mjs";

// src/components/Button/Button.tsx
import React from "react";
var typeClasses = {
  primary: `bg-brand-primary text-white px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-600
  active:bg-brand-color-library-blue-800
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  blueOutlined: `border border-brand-primary px-4 text-brand-primary
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-100
  active:bg-brand-color-library-blue-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  grayOutlined: `border border-brand-color-library-gray-800 text-brand-color-library-gray-800 px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-gray-100
  active:bg-brand-color-library-gray-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  dangerOutlined: `border border-brand-color-library-red-800 text-brand-color-library-red-800 px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-red-100  
  active:bg-brand-color-library-red-300 
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  blueText: `text-brand-primary px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-blue-100
  active:bg-brand-color-library-blue-200
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  dangerText: `text-brand-error px-4
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-red-100  
  active:bg-brand-color-library-red-300 
  focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-brand-primary`,
  icon: `p-[5px] rounded
  ease-out hover:transition duration-300 active:duration-0
  hover:bg-brand-color-library-gray-100 
  active:bg-brand-color-library-gray-200`
};
var sizeClasses = {
  sm: "py-2 label2 rounded",
  lg: "py-2 label1 w-full rounded lg:w-auto"
};
var iconColourMap = {
  primary: "fill-white",
  blueOutlined: "fill-brand-primary",
  grayOutlined: "fill-brand-color-library-gray-800",
  dangerOutlined: "fill-brand-color-library-red-800",
  blueText: "fill-brand-primary",
  dangerText: "fill-brand-error"
};
var iconSizeClass = {
  sm: "w-[18px] h-[18px]",
  lg: "w-6 h-6"
};
var Button = ({
  variant = "primary",
  children = "Submit",
  size = "sm",
  classes = "",
  isLoading,
  prefixIcon,
  suffixIcon,
  icon,
  ...rest
}) => {
  return /* @__PURE__ */ React.createElement("button", {
    ...rest,
    className: `disabled:opacity-60 ${typeClasses[variant]} ${variant !== "icon" ? sizeClasses[size] : ""}
       ${classes}`
  }, isLoading ? /* @__PURE__ */ React.createElement("div", {
    className: "w-24"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "inline-block h-4 w-4\n            animate-spin \n            rounded-full  \n            border-t-2 \n            border-t-gray-500"
  })) : variant !== "icon" ? /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center justify-center gap-1"
  }, prefixIcon && /* @__PURE__ */ React.createElement("div", {
    className: `flex items-center justify-center
              ${iconSizeClass[size]}
              ${iconColourMap[variant]}`
  }, prefixIcon), children, suffixIcon && /* @__PURE__ */ React.createElement("div", {
    className: `flex items-center justify-center
              ${iconSizeClass[size]}
              ${iconColourMap[variant]} `
  }, suffixIcon)) : /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center justify-center gap-2"
  }, icon));
};
Button.displayName = "Button";

// src/components/Button/IconButton.tsx
import React2 from "react";
var IconButton = (props) => {
  return /* @__PURE__ */ React2.createElement(Button, {
    ...props,
    classes: `${props.classes}`,
    variant: "icon",
    children: ""
  });
};

// src/components/ArticleCard/ArticleCard.tsx
import React3 from "react";
var ArticleCard = ({
  article,
  classes,
  ...htmlAttrProps
}) => {
  return /* @__PURE__ */ React3.createElement("div", {
    role: "listitem",
    "aria-label": article.title,
    "data-testid": "petTile",
    className: `mb-2.5 flex rounded-lg ${classes} min-w-[280px] overflow-hidden border-[1px] 
       border-brand-color-library-gray-200 !no-underline shadow-dp2`,
    ...htmlAttrProps
  }, /* @__PURE__ */ React3.createElement("a", {
    href: article.url,
    target: "_blank",
    "data-testid": "article",
    title: article.title,
    className: "w-full !no-underline",
    rel: "noreferrer"
  }, !article.image ? /* @__PURE__ */ React3.createElement("div", {
    className: ``,
    "data-testid": "defaultImg"
  }, /* @__PURE__ */ React3.createElement(DefaultPetProfile_default, {
    className: "h-28 w-full md:h-44"
  })) : /* @__PURE__ */ React3.createElement("img", {
    src: article.image,
    alt: article.title + " image",
    className: "h-24 w-full object-cover lg:h-40",
    "data-testid": "articleImg"
  }), /* @__PURE__ */ React3.createElement("h3", {
    className: "body3bold lg:label2 w-full p-4 lg:p-6"
  }, article.title)));
};
ArticleCard.displayName = "ArticleCard";

// src/components/ProductCard/ProductCard.tsx
import React5 from "react";

// src/components/ProductCard/ProductCardFilter.tsx
import React4 from "react";
var ProductCardImageFilter = () => {
  return /* @__PURE__ */ React4.createElement("div", {
    "aria-label": "filter-image",
    role: "img",
    className: `h-28 w-28 rounded bg-[#F2F2F2] 
    bg-gradient-to-r from-[rgba(0,0,0,0.00)_0%] to-[rgba(0,0,0,0.10)_100%] lg:h-60
    lg:w-full`
  });
};
var ProductCardTextFilter = () => {
  return /* @__PURE__ */ React4.createElement("div", {
    "aria-label": "filter-textlines",
    role: "article",
    className: "flex flex-col items-start gap-2 self-stretch lg:gap-3"
  }, /* @__PURE__ */ React4.createElement("div", {
    className: `h-3.5 w-full rounded
      bg-[#F2F2F2] bg-gradient-to-r
      from-[rgba(0,0,0,0.00)_0%] to-[rgba(0,0,0,0.10)_100%] lg:h-[18px]`
  }), /* @__PURE__ */ React4.createElement("div", {
    className: `h-3.5 w-full rounded bg-[#F2F2F2]
      bg-gradient-to-r  from-[rgba(0,0,0,0.00)_0%]
      to-[rgba(0,0,0,0.10)_100%] lg:hidden lg:h-[18px]
      `
  }), /* @__PURE__ */ React4.createElement("div", {
    className: `h-3.5 w-[121px] rounded bg-[#F2F2F2]
      bg-gradient-to-r from-[rgba(0,0,0,0.00)_0%]
      to-[rgba(0,0,0,0.10)_100%] lg:h-[18px] lg:w-[230px]`
  }));
};

// src/components/ProductCard/ProductCard.tsx
var ProductCard = ({
  name = "",
  id = "",
  classes = "",
  primaryProductImageUrl = "",
  select = "Select",
  isLoading = false,
  buttonCallback,
  ...rest
}) => {
  return /* @__PURE__ */ React5.createElement("div", {
    "aria-label": "productCard",
    "data-testid": "productCard",
    className: "flex w-[320px] flex-row items-center justify-between gap-2 rounded py-4 px-5 lg:w-[370px] lg:flex-col lg:gap-6 lg:px-4 ",
    ...rest,
    id,
    key: id
  }, isLoading ? /* @__PURE__ */ React5.createElement(ProductCardImageFilter, null) : /* @__PURE__ */ React5.createElement("div", {
    className: "w-28 lg:w-full"
  }, /* @__PURE__ */ React5.createElement("img", {
    className: "mx-auto w-full lg:h-40 lg:w-auto",
    src: primaryProductImageUrl,
    alt: name,
    "aria-label": "product-image"
  })), /* @__PURE__ */ React5.createElement("div", {
    className: "flex w-full flex-[1_0_0%] flex-col gap-4 lg:gap-6"
  }, isLoading ? /* @__PURE__ */ React5.createElement(ProductCardTextFilter, null) : /* @__PURE__ */ React5.createElement("div", {
    className: "body3 lg:label1"
  }, name), /* @__PURE__ */ React5.createElement(Button, {
    variant: "blueOutlined",
    "aria-label": "select product",
    type: "button",
    classes: "w-full lg:label1",
    onClick: () => {
      !isLoading && buttonCallback(id);
    },
    size: "sm"
  }, select)));
};
ProductCard.displayName = "ProductCard";

// src/components/Loader/Loader.tsx
import React6 from "react";
import { classnames } from "utils";
var sizeMap = {
  small: "h-4 w-4",
  medium: "h-8 w-8",
  large: "h-12 w-12"
};
var Loader = ({ size = "medium" }) => {
  return /* @__PURE__ */ React6.createElement("div", {
    className: `text-center`
  }, /* @__PURE__ */ React6.createElement("div", {
    role: "status"
  }, /* @__PURE__ */ React6.createElement("svg", {
    className: classnames("mr-2 inline animate-spin fill-blue-600 text-gray-200 dark:text-gray-600", sizeMap[size]),
    viewBox: "0 0 100 101",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React6.createElement("path", {
    d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
    fill: "currentColor"
  }), /* @__PURE__ */ React6.createElement("path", {
    d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
    fill: "currentFill"
  })), /* @__PURE__ */ React6.createElement("span", {
    className: "sr-only"
  }, "Loading...")));
};
Loader.displayName = "Loader";

// src/components/Sidebar/Sidebar.tsx
import React8 from "react";

// src/components/Sidebar/SidebarItems.tsx
import React7 from "react";
var SidebarItems = ({ item, render }) => {
  var _a;
  return /* @__PURE__ */ React7.createElement("div", {
    className: "w-full",
    "data-testid": "sidebar_items"
  }, render(item, false, 0), item.subcategory && ((_a = item.subcategory) == null ? void 0 : _a.map((subcat, index) => {
    return render(subcat, true, index);
  })));
};
var SidebarItems_default = SidebarItems;

// src/components/Sidebar/Sidebar.tsx
var Sidebar = ({ items, render }) => {
  return /* @__PURE__ */ React8.createElement("div", {
    className: "hidden lg:flex h-fit w-[256px] flex-col items-start inline-block rounded-l-lg border-r-[1px] border-brand-color-library-gray-200"
  }, items.map((item, index) => {
    return /* @__PURE__ */ React8.createElement(SidebarItems_default, {
      key: index,
      item,
      render
    });
  }));
};
Sidebar.displayName = "Sidebar";

// src/components/AppProtectedLayout/AppProtectedLayout.tsx
import React9 from "react";
function AppProtectedLayout({
  sideMenu,
  content,
  upperContent,
  lowerContent
}) {
  return /* @__PURE__ */ React9.createElement("div", {
    className: "grid_v2 container mt-0 w-full justify-center py-5 px-0 lg:grid-cols-[256px_552px] gap-y-8 lg:gap-x-20"
  }, /* @__PURE__ */ React9.createElement("div", {
    className: "col-span-2 hidden lg:block"
  }, upperContent), sideMenu, /* @__PURE__ */ React9.createElement("main", {
    className: "w-full lg:w-[552px]"
  }, content), lowerContent && /* @__PURE__ */ React9.createElement("div", {
    className: "col-span-2 lg:pt-2"
  }, lowerContent));
}
AppProtectedLayout.displayName = "AppProtectedLayout";

// src/components/Tabs/Tabs.tsx
import React11, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

// src/components/Tabs/TabTitle.tsx
import React10 from "react";
import { classnames as classnames2 } from "utils";
var TabTitle = ({
  myRef,
  title,
  activeItemIdx,
  setActive,
  index
}) => {
  return /* @__PURE__ */ React10.createElement("button", {
    id: `tab-${index}`,
    type: "button",
    role: "tab",
    ref: activeItemIdx === index ? myRef : null,
    "aria-selected": activeItemIdx === index,
    "aria-controls": `tabpanel-${index}`,
    onClick: () => setActive(index),
    className: classnames2(activeItemIdx === index ? "bold border-b-[3px] border-brand-primary text-brand-primary focus:outline-none" : "hover:border-brand-color-library-blue-100 focus:rounded", "body3 lg:body1", "mx-1 mt-3 rounded-t py-2 px-3 text-center hover:cursor-pointer hover:bg-brand-color-library-blue-100 focus:z-[1]", "focus:outline focus:outline-1 focus:outline-brand-color-library-blue-500 lg:py-3 lg:px-8")
  }, title);
};

// src/components/Tabs/Tabs.tsx
var Tabs = ({
  children,
  classes,
  activeIndex,
  onActiveIndexChange
}) => {
  const [_activeItemIdx, setActiveItemIdx] = useState(activeIndex || 0);
  const activeItemIdx = activeIndex !== void 0 ? activeIndex : _activeItemIdx;
  const handleActiveItemIndexChange = useCallback(function handleActiveItemIndexChange2(index) {
    const updateFn = onActiveIndexChange || setActiveItemIdx;
    updateFn(index);
  }, [onActiveIndexChange]);
  const tabItemRef = useRef(null);
  useEffect(() => {
    const selectedtab = tabItemRef.current;
    if (selectedtab) {
      selectedtab.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center"
      });
    }
  }, [activeItemIdx]);
  return /* @__PURE__ */ React11.createElement("div", {
    className: `${classes} w-full`
  }, /* @__PURE__ */ React11.createElement("div", {
    role: "tablist",
    className: `no-scrollbar flex items-center overflow-x-auto border-b-[1px] `
  }, children.map((item, index) => {
    return /* @__PURE__ */ React11.createElement(TabTitle, {
      myRef: tabItemRef,
      key: index,
      title: item.props.title,
      setActive: handleActiveItemIndexChange,
      activeItemIdx,
      index
    });
  })), children.map((item, idx) => /* @__PURE__ */ React11.createElement("div", {
    key: idx,
    id: `tabpanel-${idx}`,
    role: "tabpanel",
    tabIndex: 0,
    "aria-labelledby": `tab-${idx}`,
    className: `${activeItemIdx === idx ? "block" : "hidden"}`
  }, item.props.children)));
};

// src/components/Tabs/Tab.tsx
import React12 from "react";
var Tab = ({
  children,
  classes
}) => {
  return /* @__PURE__ */ React12.createElement("div", {
    className: classes
  }, children);
};

// src/components/Chip/Chip.tsx
import React13 from "react";
var Chip = ({ children, classes, ...props }) => {
  return /* @__PURE__ */ React13.createElement("button", {
    className: `${classes ? classes : ""}
      flex h-[38px] w-fit flex-shrink-0 items-center justify-evenly gap-2 
      rounded-[40px] bg-brand-color-library-blue-200 py-2 pl-3 pr-2 
      hover:cursor-pointer hover:bg-brand-color-library-blue-300 focus:outline 
      focus:outline-1 focus:outline-offset-1 focus:outline-brand-color-library-blue-500 
      lg:h-[42px] lg:pl-4 lg:pr-3`,
    ...props
  }, /* @__PURE__ */ React13.createElement("span", {
    className: "body3 text-brand-color-library-gray-900 "
  }, children), /* @__PURE__ */ React13.createElement("span", {
    className: "flex h-4 w-4 items-center justify-center fill-brand-color-library-gray-800 hover:cursor-pointer"
  }, IconClose_default));
};
Chip.displayName = "Chip";

// src/components/Accordion/Accordion.tsx
import React15, { useState as useState3 } from "react";

// src/components/Accordion/AccordionItem.tsx
import React14, { useEffect as useEffect2, useRef as useRef2, useState as useState2 } from "react";
var AccordionItem = ({
  data,
  isOpen,
  idx,
  btnClick
}) => {
  const contentRef = useRef2(null);
  const [height, setHeight] = useState2(0);
  useEffect2(() => {
    if (isOpen) {
      const contentEl = contentRef.current;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);
  return /* @__PURE__ */ React14.createElement("li", null, /* @__PURE__ */ React14.createElement("button", {
    type: "button",
    "aria-expanded": isOpen,
    id: `accordion${idx}id`,
    "aria-controls": `sect${idx}`,
    "aria-label": "accordion-title",
    tabIndex: 0,
    className: `offset-outline flex w-full items-center justify-between
        rounded-[5px] py-3 hover:cursor-pointer `,
    onClick: btnClick
  }, /* @__PURE__ */ React14.createElement("div", {
    className: "label2 lg:label1 text-[#44464B]"
  }, data.title), /* @__PURE__ */ React14.createElement("div", {
    className: `h-6 w-6 fill-[#0054A4] transition-all duration-200 ${isOpen ? "rotate-180" : ""}`
  }, IconDownArrow_default)), /* @__PURE__ */ React14.createElement("div", {
    className: "h-[1px] w-full bg-[#C6C7CC]"
  }), /* @__PURE__ */ React14.createElement("div", {
    "aria-labelledby": `accordion${idx}id`,
    role: "region",
    id: `sect${idx}`,
    className: `${isOpen ? "opacity-1	visible" : "invisible opacity-0"} overflow-hidden transition-all duration-200 ease-in-out`,
    ref: contentRef,
    style: { height }
  }, data.content), /* @__PURE__ */ React14.createElement("div", {
    className: `${isOpen ? "block" : "hidden"} h-[1px] w-full bg-[#C6C7CC]`
  }));
};
var AccordionItem_default = AccordionItem;

// src/components/Accordion/Accordion.tsx
var Accordion = ({
  items,
  parentClasses
}) => {
  const [currentIdx, setCurrentIdx] = useState3(-1);
  const btnClick = (idx) => {
    setCurrentIdx((currentValue) => currentValue !== idx ? idx : -1);
  };
  return /* @__PURE__ */ React15.createElement("ul", {
    className: parentClasses
  }, items.map((item, idx) => /* @__PURE__ */ React15.createElement(AccordionItem_default, {
    data: item,
    idx,
    key: idx,
    isOpen: idx === currentIdx,
    btnClick: () => btnClick(idx)
  })));
};
Accordion.displayName = "Accordion";

// src/components/DefaultImage/PetNameImage.tsx
import React16 from "react";
var PetNameDefaultImage = (petName) => {
  return /* @__PURE__ */ React16.createElement("div", {
    role: "img",
    "aria-label": "default-image",
    className: "label2 rounded-full bg-[#E6E6E6] px-[14px] py-[16px] text-center text-[#34363B]"
  }, petName.substring(0, 2));
};
PetNameDefaultImage.displayName = "PetNameDefaultImage";

// src/components/FormControls/FormControl.tsx
import React22 from "react";
import { Field } from "react-final-form";

// src/components/FormControls/GetFormControlType.tsx
import React21 from "react";

// src/components/FormControls/InputTypes/RadioInput.tsx
import React17 from "react";
import { classnames as classnames3 } from "utils";
var RadioInput = ({
  input,
  children,
  isUncheckable,
  label,
  variant,
  mode,
  invalid,
  isRequired
}) => {
  const isChecked = input.checked;
  const isBlockVariant = variant === "block";
  const handleRadioClick = () => {
    if (isUncheckable && isChecked) {
      input.onChange(null);
    } else {
      input.onChange(input.value);
    }
  };
  return /* @__PURE__ */ React17.createElement("label", {
    onClick: handleRadioClick,
    className: classnames3("group inline-flex cursor-pointer flex-col items-start  peer-disabled:opacity-40", isBlockVariant && [
      "w-full rounded border py-4 px-4",
      invalid && "border-brand-error",
      !invalid && !isChecked && !mode && "border-brand-accent-gray-light",
      !invalid && isChecked && !mode && "border-brand-primary bg-blue-100",
      !invalid && mode && "cursor-default",
      !invalid && mode === "correct" && "border-green-800 bg-green-100",
      !invalid && mode === "incorrect" && "border-red-800 bg-red-100"
    ])
  }, /* @__PURE__ */ React17.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React17.createElement("input", {
    ...input,
    type: "radio",
    className: classnames3(input.className, "peer mr-2 h-5 w-5 appearance-none rounded-full border", "bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200", "checked:border-8 checked:border-brand-primary focus:outline focus:outline-1 focus:outline-offset-1 focus:outline-[#0054A4]", "disabled:opacity-40 disabled:focus:outline-none", "group-hover:bg-[#EEF5FF] group-hover:outline group-hover:outline-2 group-hover:outline-[#EEF5FF]", mode ? "cursor-default" : "cursor-pointer", isChecked ? "border-8 border-brand-primary" : invalid ? "border-2 border-brand-error" : "border-gray-300"),
    tabIndex: 0,
    onClick: handleRadioClick
  }), label && /* @__PURE__ */ React17.createElement("span", {
    className: "body2 peer-disabled:opacity-40"
  }, label, isRequired && /* @__PURE__ */ React17.createElement("span", {
    className: "text-brand-color-library-red-800"
  }, " *"))), isBlockVariant && children);
};
var RadioInput_default = RadioInput;

// src/components/FormControls/InputTypes/CheckboxInput.tsx
import React18 from "react";
import { classnames as classnames4 } from "utils";
var CheckboxInput = ({
  input,
  label,
  invalid,
  isRequired
}) => {
  const checkboxIconStyles = `pointer-events-none h-5 w-5 rounded 
                              peer-hover:bg-brand-color-library-blue-100 
                              peer-focus:outline peer-focus:outline-1 peer-focus:outline-offset-1 
                              peer-focus:outline-brand-color-library-blue-500`;
  return /* @__PURE__ */ React18.createElement("label", {
    className: classnames4("inline-flex cursor-pointer items-center p-1", invalid && "rounded border border-brand-error")
  }, /* @__PURE__ */ React18.createElement("input", {
    ...input,
    type: "checkbox",
    className: "peer appearance-none"
  }), /* @__PURE__ */ React18.createElement("div", {
    className: [checkboxIconStyles, "block peer-checked:hidden"].join(" "),
    "aria-hidden": true
  }, invalid ? CheckboxInvalid : CheckboxBase), /* @__PURE__ */ React18.createElement("div", {
    className: [checkboxIconStyles, "hidden peer-checked:block"].join(" "),
    "aria-hidden": true
  }, invalid ? CheckboxInvalid : Checkbox), label && /* @__PURE__ */ React18.createElement("span", {
    className: "body3 lg:body2 pl-2 font-normal text-black"
  }, label, isRequired && /* @__PURE__ */ React18.createElement("span", {
    className: "text-brand-color-library-red-800"
  }, " *")));
};
var CheckboxInput_default = CheckboxInput;

// src/components/FormControls/InputTypes/InputField.tsx
import React19 from "react";
var InputField = ({
  input,
  invalid,
  label,
  isRequired
}) => {
  const defaultId = React19.useId();
  const id = input.id || defaultId;
  return /* @__PURE__ */ React19.createElement("div", null, label && /* @__PURE__ */ React19.createElement("label", {
    className: "label2 mb-2",
    htmlFor: id
  }, label, isRequired && /* @__PURE__ */ React19.createElement("span", {
    className: "text-brand-color-library-red-800"
  }, " *")), /* @__PURE__ */ React19.createElement("input", {
    ...input,
    id: "InputField",
    className: `block w-full grow appearance-none rounded-md border bg-transparent py-1 px-4 
          text-lg text-brand-color-library-gray-800 hover:border-brand-color-library-gray-500
          placeholder-brand-color-library-gray-500 border-brand-color-library-gray-300
          focus:outline focus:outline-1 focus:outline-offset-2 focus:outline-brand-primary 
           disabled:opacity-40 ${invalid ? "!border-brand-error focus:outline-0" : ""} mt-2`
  }));
};
var InputField_default = InputField;

// src/components/FormControls/InputTypes/TextAreaInput.tsx
import React20, { useState as useState4 } from "react";
var TextAreaInput = ({
  input,
  invalid,
  label,
  isRequired
}) => {
  const [lineCount, setLineCount] = useState4(1);
  const maxLines = 5;
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && lineCount >= maxLines) {
      event.preventDefault();
    }
  };
  const handleChange = (event) => {
    const lines = event.target.value.split("\n").length;
    if (lines <= maxLines) {
      setLineCount(lines);
    }
    input.onChange(event);
  };
  return /* @__PURE__ */ React20.createElement("div", {
    className: "grid_hphv2"
  }, label && /* @__PURE__ */ React20.createElement("label", {
    className: "label2",
    htmlFor: "TextareaField"
  }, label, isRequired && /* @__PURE__ */ React20.createElement("span", {
    className: "text-brand-color-library-red-800"
  }, " *")), /* @__PURE__ */ React20.createElement("textarea", {
    ...input,
    id: "TextareaField",
    className: `block grow appearance-none rounded-md
          border border-brand-color-library-gray-300 bg-transparent py-1 px-4 text-lg text-brand-color-library-gray-800 placeholder-brand-color-library-gray-500 hover:border-brand-color-library-gray-500 focus:outline focus:outline-1 focus:outline-offset-2 focus:outline-brand-primary  disabled:opacity-40 ${invalid ? "border-brand-error focus:outline-0" : ""}`,
    rows: lineCount,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    style: { overflow: "hidden", resize: "none" }
  }));
};
var TextAreaInput_default = TextAreaInput;

// src/components/FormControls/GetFormControlType.tsx
var getFormControlType = (type, input, children, isUncheckable, label, invalid, variant, mode, isRequired) => {
  switch (type) {
    case "input":
      if (input.type == "radio") {
        return /* @__PURE__ */ React21.createElement(RadioInput_default, {
          input,
          children,
          isUncheckable,
          label,
          variant,
          mode,
          invalid,
          isRequired
        });
      } else if (input.type == "checkbox") {
        return /* @__PURE__ */ React21.createElement(CheckboxInput_default, {
          input,
          label,
          invalid,
          isRequired
        });
      } else if (input.type == "textarea") {
        return /* @__PURE__ */ React21.createElement(TextAreaInput_default, {
          input,
          invalid,
          label,
          isRequired
        });
      }
      return /* @__PURE__ */ React21.createElement(InputField_default, {
        input,
        invalid,
        label,
        isRequired
      });
    default:
      return /* @__PURE__ */ React21.createElement(React21.Fragment, null, children);
  }
};
var GetFormControlType_default = getFormControlType;

// src/components/FormControls/FormControl.tsx
var FormControl = ({
  label,
  children,
  placeholder,
  classes = "",
  subtext = "",
  disabled = false,
  type = "text",
  onBlurCapture,
  onChange,
  max = "",
  fieldProps,
  flexDirection = "col",
  optionalText = "",
  isRequired = false,
  errorShort = false,
  value,
  isUncheckable = false,
  variant,
  mode,
  ...rest
}) => {
  return /* @__PURE__ */ React22.createElement("div", null, /* @__PURE__ */ React22.createElement(Field, {
    ...fieldProps,
    type
  }, ({ input, meta }) => {
    const inputProps = onChange ? {
      ...input,
      placeholder,
      disabled,
      max,
      id: input.name,
      onBlurCapture,
      onChange,
      ...rest
    } : {
      ...input,
      placeholder,
      disabled,
      max,
      id: input.name,
      onBlurCapture,
      ...rest
    };
    return /* @__PURE__ */ React22.createElement("div", {
      "data-testid": "textbox"
    }, GetFormControlType_default(fieldProps.component, inputProps, children, isUncheckable, label, meta.error && meta.touched, variant, mode, isRequired), optionalText && /* @__PURE__ */ React22.createElement(React22.Fragment, null, /* @__PURE__ */ React22.createElement("div", {
      className: "h-2"
    }), /* @__PURE__ */ React22.createElement("span", {
      className: "text-sm text-brand-color-library-gray-900"
    }, optionalText)), meta.error && meta.touched && input.type !== "radio" && /* @__PURE__ */ React22.createElement("div", null, /* @__PURE__ */ React22.createElement("span", {
      className: "h4 text-sm text-brand-error",
      "data-testid": "error-block"
    }, !errorShort && label, " ", meta.error)));
  }));
};
FormControl.displayName = "FormControl";

// src/components/FormControls/validations.tsx
var required = (str) => (value) => value ? void 0 : str ? str : "is required";
var mustBeNumber = (str) => (value) => value && isNaN(value) ? str ? str : "must be a number" : void 0;
var minValue = (min, str) => (value) => value && isNaN(value) || value >= min ? void 0 : `${str ? str : "should be greater than"} ${min}`;
var equalValue = (equalTo) => (value) => value && value.length !== equalTo ? `should be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var minLength = (equalTo) => (value) => value && value.length < equalTo ? `can only be ${equalTo} ${isNaN(value) ? "characters" : "digits"} long.` : void 0;
var minPasswordLength = (equalTo) => (value) => value && value.length < equalTo ? `At least ${equalTo} characters required.` : void 0;
var atleastOneUppercaseChar = (value) => (value == null ? void 0 : value.toString().match(/[A-Z]/)) ? void 0 : "At least one uppercase character required.";
var atleastOneLowercaseChar = (value) => (value == null ? void 0 : value.toString().match(/[a-z]/)) ? void 0 : "At least one lowercase character required.";
var atleastOneNumber = (value) => (value == null ? void 0 : value.toString().match(/[0-9]/)) ? void 0 : "At least one number required.";
var atleastOneSpecialChar = (value) => (value == null ? void 0 : value.toString().match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/)) ? void 0 : "At least one Special char";
var passwordMatch = (equalTo) => (value) => value && value !== equalTo ? `Passwords do not match.` : void 0;
var maxLength = (equalTo, str, chars, digits, long) => (value) => value && value.length > equalTo ? `${str ? str : "can only be"} ${equalTo} ${isNaN(value) ? chars ? chars : "characters" : digits ? digits : "digits"} ${long ? long : "long"}.` : void 0;
var emojiValidation = (value) => (value == null ? void 0 : value.toString().match(/\p{Extended_Pictographic}/gu)) ? "is invalid" : void 0;
var onlyCharValidation = (str) => (value) => (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str ? str : "can't contain number and special chars";
var alphaNumericFieldsValidation = (str) => (value) => (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str;
var charValidationWithEmptyString = (str) => (value) => {
  if ((value == null ? void 0 : value.toString()) === "" || (value == null ? void 0 : value.toString()) === null || (value == null ? void 0 : value.toString()) === void 0) {
    return void 0;
  } else {
    return (value == null ? void 0 : value.toString().match(/^[^(%;/*\\|[\]\-<>@+)]*$/)) ? void 0 : str ? str : "can't contain number and special chars";
  }
};
var emailValidation = (value) => (value == null ? void 0 : value.toString().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ? void 0 : "is not vaild";
var dateGreaterThan = (compareTo, msg) => (value) => value && new Date(value) < new Date(compareTo) ? msg ? msg : `  should be greater than ` : void 0;
var composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), void 0);

// src/components/ImageAvatar/ImageAvatar.tsx
import React23, { useEffect as useEffect3, useState as useState5 } from "react";
import { classnames as classnames5 } from "utils";
var ImageAvatar = ({
  src,
  initials = "?",
  defaultContainerProps: {
    className: defaultContainerStyles,
    ...defaultContainerProps
  } = {},
  imgProps: { className: imgStyles, ...imgProps } = {}
}) => {
  const [isError, setIsError] = useState5(false);
  useEffect3(() => {
    src && setIsError(false);
  }, [src]);
  return isError || !src ? /* @__PURE__ */ React23.createElement("div", {
    className: classnames5("flex h-12 w-12 items-center justify-center rounded-full bg-brand-color-library-gray-200 md:h-14 md:w-14", defaultContainerStyles),
    "data-testid": "defaultImg",
    ...defaultContainerProps
  }, /* @__PURE__ */ React23.createElement("span", {
    className: "label2 leading-none"
  }, initials)) : /* @__PURE__ */ React23.createElement("img", {
    className: classnames5("h-20 w-20 max-w-none rounded-full object-cover md:h-24 md:w-24", imgStyles),
    src,
    alt: "Your Profile",
    onError: ({ currentTarget }) => {
      currentTarget.onerror = null;
      setIsError(true);
    },
    "data-testid": "profilephoto",
    ...imgProps
  });
};
ImageAvatar.displayName = "ImageAvatar";
export {
  Accordion,
  Add_default as Add,
  AppProtectedLayout,
  ArticleCard,
  Button,
  Checkbox,
  CheckboxBase,
  CheckboxInvalid,
  Chip,
  DefaultPetProfile_default as DefaultPetProfile,
  FormControl,
  IconAdd,
  IconAddCircleOutline_default as IconAddCircleOutline,
  IconAddOutlineBlue_default as IconAddOutlineBlue,
  IconBigCalendar_default as IconBigCalendar,
  IconButton,
  IconCalendar_default as IconCalendar,
  IconCat,
  IconCatDog_default as IconCatDog,
  IconClose_default as IconClose,
  CloseLg_default as IconCloseLg,
  IconCloseOutlined_default as IconCloseOutlined,
  IconDefaultProfilePic_default as IconDefaultProfilePic,
  IconDismissButton_default as IconDismissButton,
  IconDog,
  IconDownArrow_default as IconDownArrow,
  IconEditPhoto_default as IconEditPhoto,
  IconEditSquareOutine_default as IconEditSquareOutline,
  IconExpandDown_default as IconExpandDown,
  IconFoodFinder_default as IconFoodFinder,
  IconHome,
  IconInfo_default as IconInfo,
  IconLeading_default as IconLeading,
  IconNext_default as IconNext,
  IconPrev_default as IconPrev,
  IconSetting,
  ToastCross_default as IconToastCross,
  ToastSuccess_default as IconToastSuccess,
  IconWarning_default as IconWarning,
  ImageAvatar,
  Loader,
  PersonOutline_default as PersonOutline,
  PetNameDefaultImage,
  ProductCard,
  Sidebar,
  Tab,
  Tabs,
  alphaNumericFieldsValidation,
  atleastOneLowercaseChar,
  atleastOneNumber,
  atleastOneSpecialChar,
  atleastOneUppercaseChar,
  charValidationWithEmptyString,
  composeValidators,
  dateGreaterThan,
  emailValidation,
  emojiValidation,
  equalValue,
  maxLength,
  minLength,
  minPasswordLength,
  minValue,
  mustBeNumber,
  onlyCharValidation,
  passwordMatch,
  required
};
