import { RefObject } from "react";
import generatePDF, { Resolution } from "react-to-pdf";

export const renderPDF = async (
  targetRef: RefObject<HTMLDivElement>,
  filename: string
): Promise<Blob | null> => {
  const element = targetRef.current;
  if (!element) {
    console.error("Element not found");
    return null;
  }

  try {
    // Temporarily make the element visible if it's hidden
    const wasHidden = element.classList.contains("hidden");
    if (wasHidden) {
      element.classList.remove("hidden");
      element.style.position = "absolute";
      element.style.left = "-9999px";
      element.style.top = "-9999px";
    }

    // Wait for all images to load
    await Promise.all(
      Array.from(element.getElementsByTagName("img")).map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) {
              resolve(null);
            } else {
              img.onload = () => resolve(null);
              img.onerror = () => resolve(null);
            }
          })
      )
    );

    const pdf = await generatePDF(targetRef, {
      method: "build",
      filename,
      resolution: Resolution.HIGH,
      page: {
        margin: {
          bottom: 10,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      overrides: {
        pdf: {
          compress: true,
        },
        canvas: {
          useCORS: true,
        },
      },
    });

    const pdfBlob = await pdf.output("blob");

    // Restore the element's original state
    if (wasHidden) {
      element.classList.add("hidden");
      element.style.position = "";
      element.style.left = "";
      element.style.top = "";
    }

    return pdfBlob;
  } catch (error) {
    console.error("Error generating PDF:", error);
    return null;
  }
};

export const sharePDF = async (
  targetRef: RefObject<HTMLDivElement>,
  fileName: string,
  title: string,
  text: string
) => {
  const pdfBlob = await renderPDF(targetRef, fileName);
  if (pdfBlob && navigator.share) {
    const file = new File([pdfBlob], fileName, {
      type: "application/pdf",
    });

    try {
      await navigator.share({
        title: title,
        text: text,
        files: [file],
      });
      console.log("Share was successful.");
    } catch (error) {
      console.error("Error sharing:", error);
    }
  } else {
    //If web share is not supported, let's download the PDF
    console.info("Web Share API is not supported in this browser.");
    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
      // Clean up the URL object after the window is opened
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
    }
  }
};
