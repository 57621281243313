import { QOTW } from '../../../../components/QOTW';
import React from 'react'

const QOTWWrapper = () => {
  return (
    <>
        <QOTW />
    </>
  )
}
export default QOTWWrapper; 