import KebabMenu from "./components/KebabMenu";
import { ShareProfilePrint } from "./components/ShareProfilePrint";
import { PetType } from "../../../../../types";
import { useEffect, useRef, useState } from "react";
import { useFoodSelectDataContext } from "@hooks/FoodSelectDataContext";
import { sharePDF } from "@lib/common/pdf";
import { useFeatureFlagsConfig } from "@hooks/useFeatureFlags";

const PetsNameHeadingWrapper = ({
  petData,
  setLoader,
}: {
  petData: PetType;
  setLoader: (show: boolean) => void;
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const { loadHillsProducts, hillsProducts, isDataLoading } =
    useFoodSelectDataContext();
  const [foodSelectProduct, setFoodSelectProduct] = useState<{
    id: string;
    image: string;
    name: string;
    feedingGuide: string;
  } | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const { sharePetProfileEnabled } = useFeatureFlagsConfig();
  
  useEffect(() => {
    if (!isDataLoading) {
      // Call loadHillsProducts only if the data is not already loading
      loadHillsProducts(petData.petTypeId, true);
    }
  }, [petData.petTypeId, isDataLoading, loadHillsProducts]);

  useEffect(() => {
    if (
      !isDataLoading &&
      petData.petFoodTypeId === 1 &&
      petData.petFoodTypeInfo
    ) {
      // Find the product details based on petFoodTypeInfo
      const productDetails = hillsProducts.find(
        (product) => product.id === petData.petFoodTypeInfo
      );
      if (productDetails) {
        setFoodSelectProduct({
          id: productDetails.id,
          image: productDetails.primaryProductImageUrl,
          name: productDetails.name,
          feedingGuide: productDetails.feedingGuide || "",
        });
      }
    }
  }, [
    petData.petFoodTypeId,
    petData.petFoodTypeInfo,
    hillsProducts,
    isDataLoading,
  ]);

  const handleShareProfileClick = async () => {
    setIsSharing(true);
    try {
      await sharePDF(
        targetRef,
        "pet-profile.pdf",
        "Pet Profile",
        "Share pet profile"
      );
    } catch (error) {
      console.error("Error sharing PDF:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsSharing(false);
    }
  };

  return (
    <>
      <div className="headline4 lg:headline1 ml-5 flex items-center">
        <span>{petData.petName}</span>
        <KebabMenu
          petData={petData}
          setLoader={setLoader}
          handleShareProfileClick={handleShareProfileClick}
          isSharing={isSharing}
          sharePetProfileEnabled={sharePetProfileEnabled}
        />
      </div>
      <div ref={targetRef} className="w-[834px] hidden">
        <ShareProfilePrint
          petImage={petData.petImage ?? ""}
          nickname={petData.petName}
          breedId={petData.petBreedId ?? 0}
          genderId={petData.petGenderId ?? 0}
          birthdate={
            petData.petBirthday ? new Date(petData.petBirthday) : new Date()
          }
          petTypeId={petData.petTypeId ?? 0}
          sizeId={petData.petSizeId ?? 0}
          productName={foodSelectProduct?.name || ""}
          productImageUrl={foodSelectProduct?.image || ""}
          feedingGuide={foodSelectProduct?.feedingGuide || ""}
        />
      </div>
    </>
  );
};

export default PetsNameHeadingWrapper;
