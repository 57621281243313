export const MY_PETS = {
  HEADING: "myPets.heading",
  CAROUSAL_HEADER: "myPets.carousalHeader",
  CAROUSAL_ADD_PET: "myPets.carousalAddPet",
  ADDPET: "myPets.addPet",

  NO_PETS: {
    LABEL1: "myPets.NoPets.label1",
    LABEL2: "myPets.NoPets.label2",
    BUTTON_LABEL: "myPets.carousalAddPet",
  },
  PROFILE: {
    HEADING: "myPets.profile.heading",
    LABEL1: "myPets.profile.label1",
    LABEL2: "myPets.profile.label2",
    EDIT: "myPets.profile.edit",
    NO_BREED: "myPets.profile.noBreed",
    NONE: "myPets.profile.none",
    DIET_NAVIGATION: "myPets.profile.dietNavigation",
  },
  VET: {
    HEADING: "myPets.vet.heading",
  },
  MILESTONES: {
    HEADING: "myPets.milestones.heading",
    LIFESTAGE: "myPets.milestones.lifestage",
    PUPPY: "myPets.milestones.puppy",
    ADULT_DOG: "myPets.milestones.adultDog",
    SENIOR_DOG: "myPets.milestones.seniorDog",
    KITTEN: "myPets.milestones.kitten",
    ADULT_CAT: "myPets.milestones.adultCat",
    SENIOR_CAT: "myPets.milestones.seniorCat",
    UPCOMING_MILESTONES: "myPets.milestones.upcomingMilestones",
    COMPLETED_MILESTONES: "myPets.milestones.completedMilestones",
    NO_MILESTONES: "myPets.milestones.noMilestones",
  },
  DIET: {
    HEADING: "myPets.diet.heading",
    SELECT_FOOD: "myPets.diet.selectFood",
    FOOD_QUIZ_QUESTION: "myPets.diet.foodQuizQuestion",
    FOOD_QUIZ_LINK: "myPets.diet.foodQuizLink",
    FEEDING_GUIDE: "foodSelect.feedingGuide",
    EDIT: "myPets.diet.edit",
  },
  REMEMBRED_PETS: {
    HEADING: "myPets.rememberedPets.rememberedPetsHeading",
    LABEL: "myPets.rememberedPets.rememberedPetsLabel",
    VIEW_MY_PETS: "myPets.rememberedPets.viewMyPets",
  },
  ADD_EDIT_PET: {
    SMALL_WEIGHT_RANGE_DOG: "myPets.addEditPet.smallWeightRangeDog",
    MEDIUM_WEIGHT_RANGE_DOG: "myPets.addEditPet.mediumWeightRangeDog",
    LARGE_WEIGHT_RANGE_DOG: "myPets.addEditPet.largeWeightRangeDog",
    SMALL_WEIGHT_RANGE_CAT: "myPets.addEditPet.smallWeightRangeCat",
    MEDIUM_WEIGHT_RANGE_CAT: "myPets.addEditPet.mediumWeightRangeCat",
    LARGE_WEIGHT_RANGE_CAT: "myPets.addEditPet.largeWeightRangeCat",
    MALE: "myPets.addEditPet.Male",
    FEMALE: "myPets.addEditPet.Female",
  },
  SHOW_HIDE_POPUP: {
    SHARING: "myPets.showHidePopup.sharing",
    SHARE_PROFILE: "myPets.showHidePopup.shareProfile",
    UNHIDE_PET: "myPets.showHidePopup.unhidePet",
    HIDE_PET: "myPets.showHidePopup.hidePet",
    DELETE_PET: "myPets.showHidePopup.deletePet",
  },
};
