import { useState } from "react";
import { ROUTES_PATH } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CONST_QOTW } from "../../constants/qotw";
import { Button } from "ui-2";

const QOTW = () => {
    const [isAlreadyAnswered] = useState(false); //TODO: setIsAlreadyAnswered true if its already answered
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const onClickAnswer = () => {
        navigate(ROUTES_PATH.QOTW);
    } 
    
    return (
        <div
            className={`flex max-w-2xl flex-row items-start rounded-lg bg-[#EEF5FF] lg:p-6 p-4`}
        >
            <div className="flex flex-col">
                <div className="flex gap-4 items-center">
                    <img src={process.env.REACT_APP_URL + "/images/qotw-png.png"} alt="Question of the Week" />
                    <div className="label2 lg:label1" data-testid="header">{t(CONST_QOTW.TITLE)}</div>
                </div>
                {isAlreadyAnswered ? 
                    <div className="body3 lg:body2 py-6" data-testid="body">
                        {t(CONST_QOTW.ALREADY_ANSWERED_TEXT)}
                    </div>
                : 
                    <>
                        <div className="body3 lg:body2 py-6" data-testid="body">
                            {t(CONST_QOTW.QUESTION)}
                        </div>
                        <div className="flex justify-end w-full">
                            <Button
                                variant="primary"
                                size="lg"
                                type="button"
                                onClick={onClickAnswer}
                                classes="lg:px-8 label2 lg:label1"
                            >
                                {t(CONST_QOTW.CTA)}
                            </Button>
                        </div>
                    </>
                }
            </div>

        </div>
    );
};

export default QOTW;