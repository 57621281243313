import React from 'react';
import { HideIcon,DeleteIcon,CloseIcon,ShowIcon, ShareIcon } from '../../../../../icons';
import { MY_PETS } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { useMyPets } from '../../.././../../hooks/useMyPet';

interface MobileKebabModalProps {
  show: boolean;
  onClose: () => void;
  hideShowtext: string;
  handleShareProfileClick: () => void;
  handleShowHideClick: () => void;
  handleDeleteClick: () => void;
}

const MobileKebabModal: React.FC<MobileKebabModalProps> = ({
  show,
  onClose,
  hideShowtext,
  handleShareProfileClick,
  handleShowHideClick,
  handleDeleteClick,
}) => {
  const { t } = useTranslation();
  const { isViewRememberedPets } = useMyPets();
  const hideShowIcon = isViewRememberedPets ? ShowIcon : HideIcon;
  if (!show) return null;

  return (
    <div className="fixed inset-0 z-[200] flex items-end justify-center bg-black bg-opacity-50">
      <div className="relative w-full rounded-t-lg bg-white p-6">
        <button onClick={onClose} className="absolute top-4 right-4">
          <CloseIcon className="h-6 w-6" />
        </button>
        <div className="mt-4 flex flex-col">
          <div
            className="label2 mx-6 my-4 flex cursor-pointer items-center justify-center text-brand-primary"
            onClick={handleShareProfileClick}
          >
            <span className="mr-2">{ShareIcon}</span>
            {t(MY_PETS.SHOW_HIDE_POPUP.SHARE_PROFILE)}
          </div>
          <div
            className="label2 mx-6 my-4 flex cursor-pointer items-center justify-center text-brand-primary"
            onClick={handleShowHideClick}
          >
            <span className="mr-2">{hideShowIcon}</span>
            {hideShowtext}
          </div>
          <div
            className="label2 mx-6 my-4 flex cursor-pointer items-center justify-center text-brand-error"
            onClick={handleDeleteClick}
          >
            <span className="mr-2">{DeleteIcon}</span>
            {t("myPets.showHidePopup.deletePet")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileKebabModal;
