import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Modal from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button } from "ui-2";
import { ROUTES } from "../../../constants/routes";

export function QOTWModal() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedChoice, setSelectedChoice] = useState<number | null>(null);

  const question: string = t("QOTW.Question1.question");
  const choices: string[] = t("QOTW.Question1.choices", { returnObjects: true });

  return (
    <Modal.Root 
      open={true}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          navigate(ROUTES.ROOT + ROUTES.ACCOUNT_HOME);
        }
    }}>
      <Modal.SideModal title={t("QOTW.QOTWCard.title")}>
        <div className="flex flex-col items-center text-left p-6">
          <img src={process.env.REACT_APP_URL + "/images/qotw-png.png"} alt="QOTW" className="mb-4 w-16 h-16" />
          <div className="w-full md:w-[58%] mx-auto">
            <div className="mb-4 w-[90%] md:w-[76%] text-left">
              <h2 className="mb-4 text-lg">{question}</h2>
            </div>  
            {choices.map((choice: string, index: number) => (
              <label key={index} className={classNames("flex items-center mb-2 p-4 border rounded-md cursor-pointer text-lg", {
                "border-brand-color-library-blue-500 bg-brand-color-library-blue-100": selectedChoice === index,
                "border-gray-300 hover:bg-gray-100": selectedChoice !== index
              })}>
                <input
                  type="radio"
                  name="qotw"
                  value={index}
                  checked={selectedChoice === index}
                  onChange={() => setSelectedChoice(index)}
                  className="hidden"
                />
                 <span className={classNames("w-5 h-5 flex justify-center items-center border rounded-full mr-2", {
                  "border-brand-color-library-blue-500 bg-brand-color-library-blue-500": selectedChoice === index,
                  "border-gray-300": selectedChoice !== index
                })}>
                  {selectedChoice === index && (
                    <span className="w-[4px] h-[4px] bg-white rounded-full"></span>
                  )}
                </span>
                {choice}
              </label>
            ))}
          </div>
          <div className="w-full mt-4 pt-4 flex flex-col lg:flex-row justify-end items-center">
            <Modal.Footer>
            <Button
              type="submit"
              classes="lg:label1 w-full lg:w-[207px] lg:mt-0 lg:mb-0 mb-20 py-4"
              data-testid="submit-button"
              onClick={() => console.log('Submit answer')}
              disabled={selectedChoice === null}
            >
              {t("QOTW.QOTWModal.submit")}
            </Button>

            </Modal.Footer>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}