import axios from "axios";
import { API_URLS } from "../constants/apiUrls";
import { PetType } from "../../types";

interface FetchPetsResponse {
  data: PetType[];
  serviceStatus: {
    message: string;
  };
}

export async function fetchPets() {
  return await axios
    .get<FetchPetsResponse>(
      `${process.env.REACT_APP_PET_API_URL}${API_URLS.PETS}`
    )
    .then((response) => {
      return response;
    });
}
